export default  [
    {
      title: '会员中心',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/vip",
      routerName: "Vip",
      component: "Vip",
      children: [
        {
          path: "index",
          component: "vip/Index",
        },
        {
          path: "list",
          component: "vip/List",
        },
      ],
    }, {
      title: '库存查询',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/stockcheck",
      routerName: "Stockcheck",
      component: "Stockcheck",
      children: [
        {
          path: "index",
          component: "stockcheck/Index",
        },
        {
          path: "detail",
          component: "stockcheck/Detail",
        },
      ],
    },
     {
      title: '顾客查询',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/extcustomerTemp",
      routerName: "extcustomerTemp",
      component: "extcustomerTemp",
      children: [
        {
          path: "index",
          component: "extcustomerTemp/Index",
        },
        {
          path: "list",
          component: "extcustomerTemp/InfoDetail",
        },
      ],
    }
    
    // {
    //   title: '顾客查询',
    //   icon: {
    //     type: 'image',
    //     value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
    //     color: '1234',
    //   },
    //   routerPath: "/extcustomerTemp",
    //   routerName: "extcustomerTemp",
    //   component: "extcustomerTemp",
    //   children: [
    //     {
    //       path: "index",
    //       component: "extcustomerTemp/Index",
    //     },
    //     {
    //       path: "list",
    //       component: "extcustomerTemp/InfoDetail",
    //     },
    //   ],
    // }
    , {
      title: '门店订单评价查询',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/storecomment",
      routerName: "Storecomment",
      component: "Storecomment",
      children: [
        {
          path: "index",
          component: "storecomment/Index",
        }
      ],
    }, {
      title: '业绩分析',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/storeanalysis",
      routerName: "StoreAnalysis",
      component: "StoreAnalysis",
      children: [
        {
          path: "dev",
          component: "storeAnalysis/Index",
        },
        {
          path: "index",
          component: "storeAnalysis/IndexDev",
        },
        {
          path: "lastsale",
          component: "storeAnalysis/compareData"
        },
        {
          path: "moredata",
          component: "storeAnalysis/moreSaleData"
        }, {
          path: "catedetail",
          component: "storeAnalysis/cateDetail"
        }
        
      ],
    }, {
      title: '联系人详情',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/contactdetail",
      routerName: "ContactDetail",
      component: "ContactDetail",
      children: [
        {
          path: "index",
          component: "contactDetail/Index",
        }
      ],
    }, {
      title: '联系人详情测试',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/contactdetailtest",
      routerName: "ContactDetailTest",
      component: "ContactDetailTest",
      children: [
        {
          path: "index",
          component: "contactDetailTest/Index",
        }
      ],
    }, {
      title: '外部联系人统计',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/externalStatistics",
      routerName: "ExternalStatistics",
      component: "ExternalStatistics",
      children: [
        {
          path: "index",
          component: "externalStatistics/Index",
        },
        {
          path: "storeDetail",
          component: "externalStatistics/StoreDetail",
        }
      ],
    }, {
      title: 'OA平台demo',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/oaplatform",
      routerName: "OAPlatform",
      component: "OAPlatform",
      children: [
        {
          path: "index",
          component: "oaplatform/Index",
        },
        {
          path: "list",
          component: "oaplatform/List",
        }, {
          path: "listother",
          component: "oaplatform/Listother",
        },
        {
          path: "fkin",
          component: "oaplatform/Fkin",
        },
        {
          path: "fkinprocess",
          component: "oaplatform/Fkinprocess",
        },
        {
          path: "fkoff",
          component: "oaplatform/Fkoff",
        },
        {
          path: "fkoffprocess",
          component: "oaplatform/Fkoffprocess",
        }, {
          path: "hprocess",
          component: "oaplatform/Hprocess",
        }, {
          path: "entery",
          component: "oaplatform/Entery",
        }, {
          path: "itp",
          component: "oaplatform/Itp",
        }
        , {
          path: "hrgprocess",
          component: "oaplatform/HRGprocess",
        }, {
          path: "yongren",
          component: "oaplatform/Yongren",
        }
        , {
          path: "yrgprocess",
          component: "oaplatform/Yrprocess",
        }
        , {
          path: "detail/yrgprocess",
          component: "oaplatform/detail/Yrprocess",
        }, {
          path: "detail/hrgprocess",
          component: "oaplatform/detail/HRGprocess",
        }, {
          path: "detail/hprocess",
          component: "oaplatform/detail/Hprocess",
        },
        {
          path: "detail/fkoffprocess",
          component: "oaplatform/detail/Fkoffprocess",
        },
        {
          path: "detail/fkinprocess",
          component: "oaplatform/detail/Fkinprocess",
        }



      ],
    }, {

      title: '终端门店巡检',

      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/shopinspect",
      routerName: "Shopinspect",
      component: "Shopinspect",
      children: [
        {
          path: "index",
          component: "shopinspect/Index",
        },
        {
          path: "groupadd",
          component: "shopinspect/GroupAdd",
        },
        {
          path: "store",
          component: "shopinspect/Store",
        },
        {
          path: "start",
          component: "shopinspect/Start",
        },
        {
          path: "list",
          component: "shopinspect/List",
        },
        {
          path: "detail",
          component: "shopinspect/Detail",
        }]

    },
    {
      title: '报工',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/jobbook",
      routerName: "JobBook",
      component: "JobBook",
      children: [
        {
          path: "index",
          component: "jobBook/Index",
        },
        {
          path: "setjob",
          component: "jobBook/SetJob",
        },
        {
          path: "detail",
          component: "jobBook/Detail",
        },
      ],
    },
    {
      title: '陈列照片',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/storemanagement",
      routerName: "StoreManagement",
      component: "StoreManagement",
      children: [
        {
          path: "index",
          component: "storeManagement/PlacesList",
        },
        {
          path: "placesdetail",
          component: "storeManagement/PlacesDetail",
        },
        {
          path: "placesadjustment",
          component: "storeManagement/PlacesAdjustment",
        }
      ],
    },
    {
      title: '单款解析',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/shoppingguideproducts",
      routerName: "ShoppingGuideProducts",
      component: "ShoppingGuideProducts",
      children: [
        {
          path: "index",
          component: "shoppingGuideProducts/Home",
        },
        {
          path: "detail",
          component: "shoppingGuideProducts/Detail",
        },
        {
          path: "list",
          component: "shoppingGuideProducts/List",
        }
      ],
    },
    {
      title: '券核销',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/verifycoupon",
      routerName: "VerifyCoupon",
      component: "VerifyCoupon",
      children: [
        {
          path: "index",
          component: "verifycoupon/Index",
        },
        {
          path: "detail",
          component: "verifycoupon/Detail",
        },
        {
          path: "list",
          component: "verifycoupon/List",
        }
      ],
    },
    {
      title: '外部联系人查询',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/extcustomer",
      routerName: "ExtCustomer",
      component: "ExtCustomer",
      children: [
        {
          path: "index",
          component: "extcustomer/Index",
        },
        {
          path: "list",
          component: "extcustomer/InfoDetail",
        },
        {
          path:"unregister",
          component:"extcustomer/Unregister"
        }
      ],
    },
    {
      title: '凤凰外部联系人',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/extcustomerPhoenix",
      routerName: "ExtCustomerPhoenix",
      component: "ExtCustomerPhoenix",
      children: [
        {
          path: "index",
          component: "extcustomerPhoenix/Index",
        },
        {
          path: "list",
          component: "extcustomerPhoenix/InfoDetail",
        },
      ],
    },
    {
      title: '货柜管理',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/storeInfo",
      routerName: "StoreContainerManagement",
      component: "StoreInfo",
      children: [
        {
          path: 'index',
          name: 'StoreContainerIndex',
          component: "storeInfo/storeContainerManagement/Index",
        },
        {
          path: 'storeContainerDetail',
          name: 'StoreContainerDetail',
          component: "storeInfo/storeContainerManagement/Detail",
        }
      ],
      
    },
      {
      title: '门店日报',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/StoreDailyReport",
      routerName: "StoreDailyReport",
      component: "StoreInfo",
      children: [
        {
          path: 'index',
          name: 'StoreDailyReportIndex',
          component: "storeInfo/storeDailyReport/Index",
        },
        {
          path: 'storeDailyReportDetail',
          name: 'StoreDailyReportDetail',
          component: "storeInfo/storeDailyReport/Detail",
        },
      ],
      
    },
    {
      title: '门店日报汇总',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/StoreManagerDailyReport",
      routerName: "StoreManagerDailyReport",
      component: "StoreInfo",
      children: [
        {
          path: 'index',
          name: 'StoreManagerDailyReportIndex',
          component: "storeInfo/storeManagerDailyReport/Index",
        },
        {
          path: 'detail',
          name: 'StoreManagerDailyReportDetail',
          component: "storeInfo/storeManagerDailyReport/Detail",
        },
        
        {
          path: 'sharelist',
          name: 'StoreManagerDailyReportShareList',
          component: "storeInfo/storeManagerDailyReport/ShareList",
        },
      ],
      
    },
    {
      title: '素材中心',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/mediaCenter",
      routerName: "MediaCenter",
      component: "MediaCenter",
      children: [
        {
          path: 'index',
          name: 'FileList',
          component: "mediaCenter/FileList",
        },
        {
          path: 'folderlist',
          name: 'FolderList',
          component: "mediaCenter/FolderList",
        },
      ],
    }, {
      title: '面料库存查询',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/goodsscan",
      routerName: "GoodsScan",
      component: "GoodsScan",
      children: [
        {
          path: "index",
          component: "goodsScan/Index",
        }
      ],
    },
    {
      title: '图片活动',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/imageActivity",
      routerName: "ImageActivity",
      component: "ImageActivity",
      children: [
        {
          path: "index",
          component: "imageActivity/Index",
        },
        {
          path: "detail",
          component: "imageActivity/Detail",
        }
      ],
    },
    {
      title: '任务管理',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/taskManage",
      routerName: "TaskManage",
      component: "TaskManage",
      children: [
        {
          path: "index",
          component: "taskManage/Index",
        },
        {
          path: "detail",
          component: "taskManage/Detail",
        },
        {
          path: "birthDetail",
          component: "taskManage/birthDetail",
        },
        {
          path: "managerIndex",
          component: "taskManage/managerIndex",
        }
      ],
    },
    {
      title: '大货库存',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/dahuo",
      routerName: "dahuo",
      component: "dahuo",
      children: [
        {
          path: "index",
          component: "dahuo/Index",
        },
        {
          path: "detail",
          component: "dahuo/Detail",
        }
      ],
    },
    {
      title: '形象打卡',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/figureSign",
      routerName: "FigureSign",
      component: "FigureSign",
      children: [
        {
          path: "index",
          component: "figureSign/Index",
        }
      ],
    },
    {
      title: '打卡统计',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/figureSignStatistics",
      routerName: "FigureSignStatistics",
      component: "FigureSignStatistics",
      children: [
        {
          path: "index",
          component: "figureSignStatistics/Index",
        },
        {
          path: "detail",
          component: "figureSignStatistics/Detail",
        }
      ],
    },
    {
      title: '全员营销',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/staffsale",
      routerName: "StaffSale",
      component: "StaffSale",
      children: [
        {
          path: "index",
          component: "staffsale/Index",
        },
        {
          path: "mygrey",
          component: "staffsale/components/MyGrey",
        },
        {
          path: "history",
          component: "staffsale/components/History",
        }
       
      ],
    },{
      title: '皮鞋激励',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/incentiveRedpacket",
      routerName: "IncentiveRedpacket",
      component: "IncentiveRedpacket",
      children: [
        {
          path: "index",
          component: "incentiveRedpacket/Index",
        },
        {
          path: "detail",
          component: "incentiveRedpacket/Detail",
        },
        {
          path: "list",
          component: "incentiveRedpacket/List",
        }
      ],
    },{
      title: '工作台',
      icon: {
        type: 'image',
        value: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        color: '1234',
      },
      routerPath: "/workspace",
      routerName: "Workspace",
      component: "Workspace",
      children: [
        {
          path: "index",
          component: "workspace/Index",
        }
      ],
    },
  ];
import { render, staticRenderFns } from "./App.vue?vue&type=template&id=6595ee18&scoped=true&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&id=6595ee18&scoped=true&lang=css&"
import style1 from "./App.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6595ee18",
  null
  
)

export default component.exports
import {QLayout,QPageContainer} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QLayout,QPageContainer})

import axios from "axios";
export const analysisLogin = async (postData) => {

  axios.post('/system/tracelog/traceLogin', postData).then((response) => {
   
    if(response.data.code==0){
      localStorage.setItem('traceLoginID',response.data.data);
    }
}).catch((error) => {
})
}

export const analysisGlobal = async (router) => {

  router.afterEach(async (to, from) => {
    let loginId = localStorage.getItem('traceLoginID');
    let previousActionId=localStorage.getItem('previousActionId');
    let timestr=new Date().getTime()
    localStorage.setItem('previousActionId', timestr)
    console.log('history.state', history,to,from)
    if(loginId&&loginId!=""){
     
      let postData = {
        "loginLogId":loginId,
        "url": to.path,
        "fullUrl": to.fullPath,
        "actionId": timestr,
        "actionCode": "afterEach",
        "type": "1",
        "actionData": "",
        previousActionId:previousActionId
      }
      axios.post('/system/tracelog/addTraceAccessLog', postData).then((response) => {
          console.log(response)
      }).catch((error) => {
      })
    }


  });
}
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Navigate from '../views/Navigate.vue'
import Test from '../views/Test.vue'
import TaskDetail from '../views/taskdetail/TaskDetail.vue'
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },{
    path: '/taskdetail',
    name: 'TaskDetail',
    component: TaskDetail,
  }, {
    path: '/navigate',
    name: 'Navigate',
    component: Navigate
}, 
  {
    path: '*',
    name: 'Test',
    component: Test
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }

  // ,{
  //   path: "/contactDetail",
  //   name: "ContactDetail",
  //   component: ()=>import('@/views/application/ContactDetail.vue'),
  //   children: [
  //     {
  //       path: "index",
  //       component: ()=>import('@/views/application/contactDetail/Index.vue'),
  //     }
  //   ],
  // },{
  //   path: "/externalStatistics",
  //   name: "ExternalStatistics",
  //   component: ()=>import('@/views/application/ExternalStatistics.vue'),
  //   children: [
  //     {
  //       path: "index",
  //       component: ()=>import('@/views/application/externalStatistics/Index.vue'),
  //     },
  //     {
  //       path: "storeDetail",
  //       component: ()=>import('@/views/application/externalStatistics/StoreDetail.vue'),
  //     }
  //   ],
  // },



];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition){
    if(savedPosition){
      return savedPosition;
    }else{
      return {
        x: 0, 
        y: 0
      }
    }
  }
});

router.afterEach(async (to, from) => {
  console.log('to',to)
  console.log('location',location)
  console.log('history.state',history)
});

export default router

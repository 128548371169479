//
//
//
//
//

export default {
  name: "Navigate",
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    navigateToApp() {
      let appName = this.$route.query.app || "";
      if (appName != "") {
        // this.axios.post({})
        // if (appName == "Stockcheck") {
        // }
        let data = [
          {
            title:'会员中心',
            icon:{
              type:'vant',
              value:'https://img.yzcdn.cn/vant/apple-1.jpg',
              color:'1234',
            },
            routerPath: "/vip",
            routerName: "Vip",
            component: "../views/application/Vip.vue",
            children: [
              {
                path: "index",
                 component: "../views/application/Vip.vue",
              },
              {
                path: "list",
                component: "../views/application/Vip.vue",
              },
            ],
          }
        ];
        console.log(data);
        let routerArr = [
          {
            path: "/vip",
            name: "Vip",
            component: () => import("../views/application/Vip.vue"),
            children: [
              {
                path: "index",
                component: () => import("../views/application/vip/Index.vue"),
              },
              {
                path: "list",
                component: () => import("../views/application/vip/List.vue"),
              },
            ],
          },
        ];

        this.$router.addRoutes(routerArr);
        console.log(this.$router);
        let indexPage = routerArr[0].path + "/index";
        setTimeout(() => {
          this.$router.push(indexPage);
        }, 2000);
        // let routes=this.$router.options.routes;
        // for (var i = 0; i < routes.length; i++) {
        //   if( routes[i].name==appName){
        //     if(!routes[i].children){
        //       routes[i].children=[]
        //     }
        //      routes[i].children=routes[i].children.concat(routerArr);

        //     break;

        //   }

        // let routerArr = [
        //   {
        //     path: "/vip",
        //     name: "Vip",
        //     component: () => import("../views/application/Vip.vue"),
        //     children: [
        //       {
        //         path: "index",
        //         component: () => import("../views/application/vip/Index.vue"),
        //       },
        //       {
        //         path: "list",
        //         component: () => import("../views/application/vip/List.vue"),
        //       },
        //     ],
        //   },
        // ];
        console.log(this.$router);
      }
    },
  },
  mounted() {
    this.$store.dispatch("authLogin").then(() => {
      console.log("this.$store.state", this.$store.state);
    });
    console.log(this.$route.query.app);
    this.navigateToApp();
    setTimeout(() => {
      // this.$router.push('/vip/index');
      // this.loading=false;
    }, 5000);
  },
};

import axios from 'axios'
import router from '../router'
import { LocalStorage } from 'quasar'

// axios.defaults.baseURL = (process.env.NODE_ENV=='development')?'/prod-api':'/prod-api';
//   axios.defaults.baseURL = (process.env.NODE_ENV=='development')?'/dev-api':'/stage-api';


   axios.defaults.baseURL = (process.env.NODE_ENV=='development')?'/stage-api':'/stage-api';
axios.interceptors.request.use(

    config => {
    
        let mytoken = LocalStorage.has('token');
        if (config.url.indexOf('auth') < 0) {

            if (mytoken) {
                let token = LocalStorage.getItem('token');
                config.headers['Authorization'] =  'Bearer ' + token;
            } else {
                router.push('/')
                return
            }
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
//在 response 拦截器实现

axios.interceptors.response.use(
    response => {

       // console.log('response', response)
        return response
    },
    error => {
       // console.log('error', error.response.status)
        // if (error.response.status == 401) {
        //     router.push('/')
        // }
        return Promise.reject(error)
    }
)


//axios.defaults.baseURL = 'https://api.example.com';

import axios from "axios";
import Vue from 'vue'
import { Dialog } from "vant";
export async function getUserInfo() {
    return await axios.get("/consumer/v1/wx/cp/getUserInfo").then((response) => {
        console.log('getUserInfo',response.data);
        if (response.data.code == 0) {

           return response.data.data

        } else {
          console.log('1')
          Dialog.alert({
            message: response.data.msg,
          }).then(() => {
            // on close
          });
        }
      }).catch((error) => {
        console.log(error);

      })

}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LocalStorage } from 'quasar'
import {getUserInfo} from '../common/utils'
import { analysisLogin } from '../lib/bxn-analysis.js'
export default {
  name: "Home",
  data() {
    return {
      menu: [],
      loading: false,
      directly: false,
    };
  },
  components: {},
  created() {
   
  },
  methods: {
    setAna: function (userInfo) {
      let postData = {
        "corpId": userInfo.corpId,
        "agentId": userInfo.agentId,
        "hrid": userInfo.hrid
      }
      analysisLogin(postData);
    },
    async login() {
      console.log(this.$router);
      if (!this.$store.state.login) {
        this.loading = true;
        var query = this.$route.query;
        if (this.$route.query.source == "ding") {
          console.log(";alidd");
          query.code = await new Promise((resolve) => {
            this.$dd.ready(() => {
              console.log("dd");
              this.$dd.biz.navigation.setTitle({
                title: "钉钉应用",
              });
              this.$dd.runtime.permission.requestAuthCode({
                corpId: "dingdd638eed5300d294", // 企业id
                onSuccess: function (info) {
                  resolve(info.code);
                },
              });
            });
          });
        }
   
        this.$store.dispatch("authLogin", query).then(async (res) => {
          this.loading = false;
           const userInfo=await getUserInfo()
           console.log('userInfo',userInfo)
           this.setAna(userInfo)
           this.setUserInfo(userInfo);
          if (this.$store.state.login) {
            this.menu = res;
            let appName = this.$route.query.app || "";
            let query = "";
            if(this.$route.query.uuid){
              query  = "?uuid=" + this.$route.query.uuid
            }
            if(this.$route.query.taskId){
              query  = "?taskId=" + this.$route.query.taskId
            }
            if (appName != "") {
              this.directly = true;
              for (let index = 0; index < this.menu.length; index++) {
                if (this.menu[index].routerName == appName) {
                  if(appName == 'StoreManagement' && this.$route.query.assessId){
                    this.$router.replace(this.menu[index].routerPath + "/placesadjustment?assessId=" + this.$route.query.assessId);
                  }else if(appName == 'IncentiveRedpacket' && this.$route.query.recordId){
                    this.$router.replace(this.menu[index].routerPath + "/detail?recordId=" + this.$route.query.recordId);
                  }else if(appName == 'StoreManagerDailyReport' && this.$route.query.dailyReportShareId){
                    this.$router.replace(this.menu[index].routerPath + "/sharelist?dailyReportShareId=" + this.$route.query.dailyReportShareId);
                  }else{
                    this.$router.replace(this.menu[index].routerPath + "/index" + query);
                  }
                  break;
                }
              }
            }
          }
        });
      }
    },
    setUserInfo(data){
  console.log('setUserInfo',data);
            LocalStorage.set('userMobile', data.mobile);
            LocalStorage.set('userIsGuide', data.guide);
            LocalStorage.set('userDeptIds', data.deptIds);
            LocalStorage.set('userOwnerShops', data.ownerShops);
            LocalStorage.set('ownerShopNames', data.ownerShopNames);
            LocalStorage.set('shopBrandCode', data.attr.brandRole);
            LocalStorage.set('userId', data.userId);
            LocalStorage.set('corpId',data.corpId);
            LocalStorage.set('agentId',data.agentId);
            LocalStorage.set('userName',data.username);
            LocalStorage.set('hrid',data.hrid);
            LocalStorage.set('brandCode', data.brandCode);        
            LocalStorage.set('storeArray', data.attr.saleStore);     
            this.$memberInfo.hhrEmpid = data.hrid
            let roleList= data.attr.roleList;
            for (let index = 0; index < roleList.length; index++) {
                 if(roleList[index].roleName=='店长'){
                    this.$memberInfo.placeLoaction=true
                 }
            }
            console.log(' this.$memberInfo', this.$memberInfo)
            // LocalStorage.set('ownerShops', 'E001Z003,BT731Z051');
            // LocalStorage.set('ownerShopNames', '河田凤凰尚品总部直销厅,报喜鸟杭州庆春银泰景昙路店');
    }
  },
  mounted() {
    this.login();
  },
};

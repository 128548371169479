//
//
//
//
//
//
//
//
//
//

export default {
  name: "pageRouterView",
  props: {
    msg: String,
  },
  data() {
    return {
      transitionName: "forward",
    };
  },
  watch: {
    $route(to) {
      if (to.params["stack-key-dir"] === "forward") {
        this.transitionName = "forward";
      } else {
        this.transitionName = "back";
      }
    },
  },
};

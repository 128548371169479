//
//
//
//
//
//
//
//
//
//


export default {
  name: 'LayoutDefault',

  data () {
    return {

    }
  },
  created() {
  },
  methods: {

  }
}
